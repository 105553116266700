import * as React from "react"
import { Link } from "gatsby"

type NavItemProps = {
  to: string
  label: string
}

const NavItem = ({ to, label }: NavItemProps) => (
  <Link
    className="text-white pb-2 text-base lg:font-bold lg:py-2 lg:px-4 lg:rounded transition duration-150 lg:hover:bg-black lg:hover:bg-opacity-20"
    activeClassName={
      "border-white border-b-4 lg:border-none lg:bg-black lg:bg-opacity-20"
    }
    to={to}
  >
    {label}
  </Link>
)

const HeaderNav = () => {
  return (
    <nav className="flex place-content-between sm:place-content-center mx-4 sm:mx-0 sm:space-x-12 lg:space-x-6">
      <NavItem to="/" label="Home" />
      <NavItem to="/publications" label="Publications" />
      <NavItem to="/software" label="Software" />
      <NavItem to="/contact" label="Contact" />
    </nav>
  )
}

export default HeaderNav
