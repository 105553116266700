import * as React from "react"

import Layout from "./src/components/Layout"

import "./src/styles/global.css"

export const onInitialClientRender = () => {
  const isIeBrowser = !!window.MSCompatibleInfo
  if (isIeBrowser) {
    window.location.replace("/ie11.html")
  }
}

export const wrapPageElement = ({ element, props }) => {
  let pageTitle = props.location.pathname.match(/[A-z]+/g)?.[0]
  if (pageTitle) {
    pageTitle = pageTitle[0].toUpperCase() + pageTitle.substring(1)
  }
  return (
    <Layout title={pageTitle} {...props}>
      {element}
    </Layout>
  )
}
