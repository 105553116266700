import * as React from "react"
import { Link } from "gatsby"

import HeaderNav from "./HeaderNav"
import SEO from "./SEO"

type LayoutProps = {
  children: React.ReactNode
  title?: string
}

const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <SEO title={title} />
      <header className="bg-gradient-to-tr from-blue-500 to-green-600 mb-3 lg:py-3 shadow">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex flex-col lg:flex-row lg:justify-end lg:items-center space-y-6 lg:space-y-0 lg:mx-40">
            <Link className="flex justify-center lg:mr-auto" to="/">
              <h1 className="text-3xl mt-3 lg:mt-0 text-white font-black">
                Cary Stothart, Ph.D.
              </h1>
            </Link>
            <HeaderNav />
          </div>
        </div>
      </header>
      <main className=" max-w-screen-xl mx-auto px-4 lg:px-40 bg-gray-50 text-gray-800 flex pb-safe">
        <div className="min-w-full pb-3">{children}</div>
      </main>
    </>
  )
}

export default Layout
